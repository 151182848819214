import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Registerform = () => {
  // State to manage input values
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Reset messages
    setError(null);
    setSuccess(null);

    // Prepare the data to be sent
    const userData = { name, email, password };

    try {
      const response = await fetch('http://90stube.online/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        // Handle response errors
        const errorData = await response.json();
        setError(errorData.message || 'Registration failed.'); // Set error message
      } else {
        const responseData = await response.json();
        setSuccess(responseData.message); // Set success message
        // Optionally, clear the form
        setName('');
        setEmail('');
        setPassword('');
      }
    } catch (error) {
      setError('An error occurred while registering.'); // Set general error message
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="container mt-4">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary mb-4">
                  Submit
                </button>
              </form>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registerform;
